import {store} from '../state/store'
import UtilMixing from '../mixings/UtilMixing'
let refreshTokenPromise;

const mountHttpError = (httpException) => {
    let error = {
        fromApi   : false,
        http_code : 500,
        http_body : {
            error : true,
            trace : 'GEN0001 - 00',
            message : 'Try again - 00',
            type :  'GENERIC - 00'
        }
    }
    if(httpException.response) {
        error = {
            fromApi  : true,
            http_code : httpException.response.status,
            http_body : {
                error : true,
                trace : ((httpException.response.data.trace ? httpException.response.data.trace : 'GEN0001')),
                message : ((httpException.response.data.message ? httpException.response.data.message : 'Try again.')),
                type :  ((httpException.response.data.type ? httpException.response.data.type : 'GENERIC')),
            }
        }
    }
    console.error(`Final Error: `, error);
    return error;
};

const setup = (http) => {
    http.interceptors.request.use (
      async function (config) {
        config.headers["access"] = await genKey(config);
        if(store.state.session.logged){
            let jwt  = store.state.session.jwt;
            let idSessao = store.state.session.sessionId;
            config.headers["Authorization"] = "Bearer "+jwt;
            config.headers["sb-session"] = idSessao;
            config.headers["sb-version"] = process.env.VUE_APP_VERSION;
        }
        return config;
      },
      function (error) {
        console.dir('error', error);
        return Promise.reject(error);
      }
    );




    http.interceptors.response.use(r => r, error => {
        if (error.config && error.response && error.response.status === 401) {
            if (!refreshTokenPromise) {
                let actualRefresh = store.state.session.refreshToken;
                refreshTokenPromise = http.post(`/session/refresh/?refreshToken=${actualRefresh}`)
                    .then(({ data }) => {
                        let jwt = data.jwt;
                        let refreshToken = data.refreshToken;
                        let sessionId = store.state.session.sessionId
                        store.commit('setLogin', {jwt, refreshToken, sessionId});
                        refreshTokenPromise = null
                        return jwt;
                    }).catch(function(){
                        alert('Erro ao refresh token! logout imediato');
                        return null;
                    });
            }
            return refreshTokenPromise.then(token => {
                if (token == null) {
                    alert("erro depois 1");
                } else { 
                    error.config.headers['X-CSRF-TOKEN'] = token
                    return http.request(error.config)
                }
            })
        }
        return Promise.reject(mountHttpError(error))
    })

}
const genKey = async (config) =>  {
    const payload = {
        iat: Math.floor(Date.now() / 1000),
        exp: Math.floor(Date.now() / 1000) + 30,
        iss: window.location.origin,
        cli: 'front',
        userId : store.state.session ? store.state.session.userId : '',
        userName : store.state.session ? store.state.session.userName : '',
        checkBody: config.data ? true : false,
        checkUrl : true,
        body: config.data ? UtilMixing.md5(JSON.stringify(config.data)) : null,
        url: `/api${config.url}`
    };
    const token = await generateJwt(payload, "YTU5YTE0M2M2N2I4MTliNzk0YT");
    return token;
}

import { Buffer } from 'buffer';

// Function to generate a base64 URL-encoded string
function base64UrlEncode(str) {
    return Buffer.from(str)
        .toString('base64')
        .replace(/=+$/, '') // Remove padding
        .replace(/\+/g, '-')
        .replace(/\//g, '_'); // Make it URL-safe
}

// Function to generate HMAC-SHA256 signature
async function generateHmacSha256Signature(secret, message) {
    const encoder = new TextEncoder();
    const keyData = encoder.encode(secret);
    const key = await crypto.subtle.importKey(
        'raw',
        keyData,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
    );
    const signature = await crypto.subtle.sign(
        'HMAC',
        key,
        encoder.encode(message)
    );
    return base64UrlEncode(new Uint8Array(signature));
}

// Function to generate a JWT
async function generateJwt(payload, secret) {
    const header = {
        alg: 'HS256',
        typ: 'JWT'
    };
    const encodedHeader = base64UrlEncode(JSON.stringify(header));
    const encodedPayload = base64UrlEncode(JSON.stringify(payload));
    const signature = await generateHmacSha256Signature(secret, `${encodedHeader}.${encodedPayload}`);
    return `${encodedHeader}.${encodedPayload}.${signature}`;
}




export default setup;