<!-- eslint-disable no-unused-vars -->
<template>
    <Layout  v-if="timeSheet">

    <div class="row mt-3 mb-3">
        <div class="col-md-3">
            <router-link :to="`/timeSheet/${timeSheet._id}/edit`"   class="btn btn btn-xl  btn-dark w-100 ">
                <em class="icon ni ni-back-arrow me-1 fs-1 "></em> Back  
            </router-link>
        </div>
        <div class="col-md-3">
            <button @click="save(true)" class="btn btn-success  btn-xl"> Save Hours</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body text-center" v-if="timeSheet">
                    
                    <AccordionList :open-multiple-items="false" >
                        <AccordionItem :defaultOpened="false" v-for="emp of timeSheet.employees" :key="emp._id">
                            <template #summary>
                                <div class="w-100">
                                    <div style="text-align:left"><em class="icon ni ni-square-fill me-1" :style="`color: ${emp.role ? emp.role.textColor : '#333'};`"></em> {{ emp.shortName}}  </div>
                                    <div class="mt-1" style="text-align:left">
                                        <small style="font-size:0.8em">Expected: <b>{{ UtilMixing.numberToTime(emp.hourExpected)}}</b></small>
                                        <small style="font-size:0.8em" class="ms-2">Overtime: <b>+{{ UtilMixing.numberToTime(emp.hourOvertime)}}</b></small>
                                        <small style="font-size:0.8em" class="ms-2">Deducted: <b>-{{ UtilMixing.numberToTime(emp.hourDeducted)}}</b></small>
                                         <small style="font-size:0.8em" class="ms-2">Worked: <b>{{ UtilMixing.numberToTime(emp.hourFinal)}}</b></small>
                                        <small style="font-size:0.8em" class="ms-2">Role: <b :style="`color: ${emp.role ? emp.role.textColor : '#333'};`">{{ emp.role ? emp.role.name : '-'}}</b></small>
                                    </div>
                                </div>

                            </template>

                            <table class="table-border dataTable-table table">
                                <thead>
                                    <tr>
                                        <th class="col-1 text-left border bg-light  " style="text-align:left">Date</th>
                                        <th v-for="i of 5" :key="i" class="col-1 text-left border bg-light" style="text-align:left">Job {{i}}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="date of emp.dates" :key="date._id">
                                        <td class="col-1 text-left border  bg-light" style="text-align:left"> 
                                            <b>{{ date.name}}</b> <br><small class="text-muted">{{ UtilMixing.onlyDate(date.date)}}</small><br>
                                             <small>Expected: {{date.hourExpected}}hrs</small> 
                                        </td>
                                        <td v-for="i of 5" :key="i" class="col-1 text-left border bg-body" style="text-align:left">
                                         
                                            <template v-if=" date.jobs.length > 0 && date.jobs.length == i-1 || i == 1 && date.jobs.length == 0"> 
                                                <button class="btn btn-info btn-sm w-100"  @click="informJob(emp._id, date._id)">Inform job</button><br>
                                            </template>
                                            <template v-else-if="date.jobs[i-1] != null"> 
                                                
                                                <small>{{ UtilMixing.numberToTime(date.jobs[i-1].hour)}}hrs</small>
                                                <select class="form-select"  v-model="date.jobs[i-1].jobId">
                                                    <option v-for="op in jobs" :key="op" :value="op._id"> {{ op.name}}</option>
                                                </select>
                                                <input type="time"  :class="date.jobs[i-1].adjustment == 0 ? 'text-muted' : date.jobs[i-1].adjustment > 0 ? 'text-success' : 'text-danger'" class="form-control" style="font-size: 1.5em; padding: 0.3em; text-align: center;" @change="calcAll(true)" v-model="date.jobs[i-1].adjustmentText">
                                                <select class="form-select" v-model="date.jobs[i-1].adjustmentType" @change="calcAll(true)">
                                                    <option :value="1">+ Overtime</option>
                                                    <option :value="-1">- Deduction</option>
                                                </select>

                                                <button class="btn mt-1 btn-outline-danger mb-1 w-100 btn-sm" @click="removeJob(emp._id, date._id, date.jobs[i-1])">Remove</button>
                                            </template>


                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        
                            <table class="table-border dataTable-table table" v-if="false">
                                <thead>
                                    <tr>
                                        <th class="col-3 text-left border bg-light" style="text-align:left">Job</th>
                                        <th class="col-1 text-center border" v-for="date of emp.dates" :key="date._id"> 
                                            {{ date.name}} <br><small class="text-muted">{{ UtilMixing.onlyDate(date.date)}}</small>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td class="col-3 text-left border bg-light" style="text-align:left">Job</td>
                                        <td class="col-1 text-center border" v-for="date of emp.dates" :key="date._id"> 
                                            {{ date.name}} <br><small class="text-muted">{{ UtilMixing.onlyDate(date.date)}}</small>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </AccordionItem>
                    </AccordionList>
                </div>
            </div>
        </div>
    </div> 
    </Layout>

    <modalCreatePayRoll ref="modalCreate" @employ-created="$refs.datatable.filter()"></modalCreatePayRoll>
</template>
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-components -->
<script>
import draggable from 'vuedraggable'
import Messages from '@/mixings/Messages';
import { vMaska } from "maska"
import moment from 'moment-timezone';
import 'vue3-tabs-chrome/dist/vue3-tabs-chrome.css'
import modalCreatePayRoll from '@/components-smartbase/payroll/modalCreatePayRoll.vue';
import badgeQuickBooksSync from '@/components-smartbase/badgeQuickBooksSync.vue'
import Layout from '@/layout/Index.vue';
import JobMixing from '@/mixings/JobMixing';
import { Block } from '@/components/block/Block.vue';
import Card from '@/components/uielements/card/Card.vue';
import DataTable2 from '@/components-smartbase/dataTable2.vue';
import UtilMixing from "../../mixings/UtilMixing";
import EmployMixing from '@/mixings/EmployMixing.js';
import badgeEmployStatus from '../../components-smartbase/employ/badgeEmployStatus.vue';
import TimeSheetMixing from '../../mixings/TimeSheetMixing.js';
export default {
  directives: { maska: vMaska },
    name: 'ProductsPage',
    mixings: [TimeSheetMixing, EmployMixing,JobMixing],
    components: {
        modalCreatePayRoll,
        badgeEmployStatus,
        badgeQuickBooksSync,
        DataTable2,
        Layout,
        Block,
        draggable,
        Card
    },
    data() {
        return {
            timeSheetId : this.$route.params.id,
            loading: false,
            timeSheet : null,
            jobs : [],
        }
    },
    mounted() {
        this.$store.commit('setPageIcon', 'share-alt')
        this.$store.commit('setPageTitle', 'List Payrolls')
    },
    computed: {

        employListComputed(){
            if(!this.employList || this.employList.length ==0) 
                return [];  

            for(var emp of this.employList){
                emp.ERRORS = [];
                
                emp.HOUR_toPayCheck = (emp.HOUR_toPayCheck ? emp.HOUR_toPayCheck : '00:00');

                emp.hourToPayCheck =  parseFloat(moment.duration(emp.HOUR_toPayCheck || '00:00').asHours());
                emp.hourToPayCash=  emp.hours - emp.hourToPayCheck;


                if(emp.hourToPayCheck > emp.hours){
                    emp.ERRORS.push('DIVERGENT_HOUR');
                    emp.HOUR_DIVERGENT = emp.hourToPayCheck - emp.hours;
                    emp.hourToPayCash =0;
                } 

            
                emp.toPayCheck = emp.salaryByHourCheck * emp.hourToPayCheck;
                emp.toPayCash = emp.salaryByHour * emp.hourToPayCash;

                emp.toPayCash = isNaN(emp.toPayCash) ? 0 : emp.toPayCash;
                emp.toPayCheck = isNaN(emp.toPayCheck) ? 0 : emp.toPayCheck;


                emp.totalPayCash = emp.toPayCash + emp.adjustmentAmount;
               
            }
            var list = this.employList;

            list.sort(function(a,b) {
                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
            });


            for(const comp of this.companyList){
                
                var total = 0;
                for(const emp of this.employList){
                    if(emp.companyId == comp._id){
                        total = total + emp.totalPayCash;
                    }
                }
                comp['TOTAL_CASH'] = total;
            }


            return list;

        },
        UtilMixing() {
            return UtilMixing
        },
        EmployMixing() {
            return EmployMixing
        }
    },
    methods: {
        calcAll(save){
            for(const emp of this.timeSheet.employees){
                emp.hourOvertime = 0;
                emp.hourFinal = 0;
                emp.hourDeducted = 0;
                for(var date of emp.dates){
                    var expected = date.hourExpected;
                    date.hourOvertime = 0;
                    date.hourDeducted = 0;
                    date.hourFinal = 0;
                    for(var job of date.jobs){
                        let [hours, minutes] = job.adjustmentText.toString().split(":").map(Number);
                        if (isNaN(hours)) hours = 0;
                        if (isNaN(minutes)) minutes = 0;
                        var adjustmentCalc = hours + (minutes / 60);
                        job.adjustment = adjustmentCalc * job.adjustmentType;
                        date.hourOvertime   = date.hourOvertime + (job.adjustment >= 0 ? job.adjustment : 0);
                        date.hourDeducted   = date.hourDeducted + (job.adjustment < 0 ? job.adjustment * -1 : 0);
                        job.hour            = expected/date.jobs.length + job.adjustment;
                        date.hourFinal      = date.hourFinal + job.hour;
                    }
                    emp.hourOvertime    = emp.hourOvertime + date.hourOvertime;
                    emp.hourDeducted    = emp.hourDeducted + date.hourDeducted;
                    emp.hourFinal       = emp.hourFinal + date.hourFinal;
                }
            }
            if(save == true)
                this.save(false);
        },
        informJob(empId, dateId){
            var date = this.timeSheet.employees.find(e=>e._id == empId).dates.find(d=>d._id ==dateId);
            date.jobs.push({
                adjustment : 0,
                adjustmentType : 1,
                adjustmentText : '00:00',   
                jobId : undefined,
                _id : UtilMixing.uuid('r'),
            })
            this.calcAll(true);
        },
        removeJob(empId, dateId, job){
            var date = this.timeSheet.employees.find(e=>e._id == empId).dates.find(d=>d._id ==dateId);
            let index = date.jobs.indexOf(job);
            date.jobs.splice(index, 1);
            this.calcAll(true);
        },
        async save(notify){
            try {
                var employeesChanged = [];
                for(const emp of this.timeSheet.employees){
                    var employee = JSON.parse(JSON.stringify(emp));
                    var initialHash = JSON.stringify(employee.hash).replace('"','').replace('"','');
                    delete employee.hash;
                    var currentHash = UtilMixing.md5(JSON.stringify(employee));
                    if(currentHash != initialHash){
                        console.log(currentHash, initialHash);
                        employeesChanged.push(employee);
                    }
                }
                if(employeesChanged.length == 0){
                    Messages.toast_warning(`No hours added or removed, nothing to be saved.`);
                    return;
                }
                if(notify)
                    var dialog = Messages.freezeLoading('Saving Changes...');
                const { data } = await TimeSheetMixing.syncTimeSheetEmployee(this.timeSheet._id,employeesChanged)

                if(notify)
                    Messages.toast_success(`Timesheet updated.`);
                //this.jobs = data.data;
            } catch (e) {
                Messages.warning('Not able to update timesheet.', e);
            } finally {
                if(notify)
                    Messages.closeFreeze(dialog);
            } 
        },
        async loadJobs(){
            
            try {
                var dialog = Messages.freezeLoading('Removing time entry...');
                const { data } = await JobMixing.filter(1000000, 0, '', 'name', 'desc', { filter : { active : true}})
                Messages.toast_success(`Worked hours removed.`);
                this.jobs = data.data;
            } catch (e) {
                Messages.warning('Not able to get the timesheet.', e);
            } finally {
                Messages.closeFreeze(dialog);
            }
        },
        async loadTimeSheet(){
            try {
                var dialog = Messages.freezeLoading('Removing time entry...');
                const {data } = await TimeSheetMixing.get(this.timeSheetId);
                Messages.toast_success(`Worked hours removed.`);
                this.timeSheet = data;

                for(const emp of this.timeSheet.employees){
                    emp.hash = UtilMixing.md5(JSON.stringify(emp));
                }


            } catch (e) {
                Messages.warning('Not able to get the timesheet.', e);
            } finally {
                Messages.closeFreeze(dialog);
            }
        }
    },
    created() {
        this.$store.commit('setPageIcon', 'chart-up');
        this.$store.commit('setPageTitle', 'Dashboard View');
        this.loadTimeSheet();
        this.loadJobs();
    }
}
</script>